
.mainContainer::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  .mainContainer::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);        /* color of the tracking area */
  }
  
  .mainContainer::-webkit-scrollbar-thumb {
    background-color: #eaecf0;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid rgba(255,255,255,0.1);  /* creates padding around scroll thumb */
  }